<!--
    This component is used to show Inputs for localization which the values can be changed of.
    On submit, the value that is transformed & ready to be sent to the server is emitted.
-->

<template>
  <form-wrapper
    ref="form"
    :show-changed="true"
    submit-button-label="Sauvegarder"
    @submit="emitPayload"
  >

    <div
      :class="isTargetLanguageSelected ? 'justify-between' : 'justify-end'"
      class="flex items-end mb-1.5"
    >
      <localization-translation-box
        :localizable-id="localizableId"
        :localizable-type="localizableType"
        @translations="onTranslationsFetched"
      />

      <slot></slot>
    </div>

    <form-grid class="grid-cols-1 xl:grid-cols-2">
      <card
        v-for="(inputList, i) in localizationCopy"
        :key="i"
        rounded
        size="small"
        type="light"
      >
        <template #title>
          <div class="flex items-center ml-1 gap-x-2">
            <button-element
              size="xxsmall"
              @click="selectAllAttributesForLocale(inputList.language)"
            >
              <fa-icon
                fixed-width
                icon="check"
              />
            </button-element>

            <div>{{ inputList.language | languageByIsoCode }}</div>
          </div>
        </template>

        <div v-for="input in inputList.inputs" :key="input[0]" class="mb-4">
          <form-localization-input
            v-model="input[1]"
            :attribute="input[0]"
            :locale="inputList.language"
            class="flex-1"
            @input="removeTranslationAttributeIfEmpty($event, input[0])"
          />
        </div>
      </card>
    </form-grid>
  </form-wrapper>
</template>

<script>
import FormWrapper from "../elements/forms/FormWrapper";
import FormGrid from "../elements/forms/FormGrid";
import FormLocalizationInput from "../elements/forms/elements/localization/FormLocalizationInput";
import Card from "../elements/cards/Card";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import LocalizationTranslationBox from "@/components/forms/localizationTranslation/LocalizationTranslationBox.vue";

export default {
  name: "UpdateLocalizationForm",
  components: {LocalizationTranslationBox, ButtonElement, Card, FormLocalizationInput, FormGrid, FormWrapper},
  props: {
    localizations: {
      type: [Object],
      required: true
    },
    localizableType: {
      type: String,
      required: true
    },
    localizableId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    localizationCopy: [],
  }),
  methods: {
    emitPayload(callback) {
      this.$emit('submit', this.getPayloadForSave);
      callback();
    },
    selectAllAttributesForLocale(locale) {
      let inputs = this.localizationCopy.find(lO => lO.language === locale).inputs; // [0] = attribute, [1] = value/text

      // Attributes with empty text will not be selected
      inputs = inputs.filter(i => {
        return i[1] !== null;
      }).flatMap(i => i[0]);

      this.$store.commit('localization/translation/setAttributes', {
        locale: locale,
        attributes: inputs
      });
    },

    // Text was changed from something to null
    // Unselect the attribute from automatic translation
    removeTranslationAttributeIfEmpty(text, attribute) {
      if (text !== null) {
        return false;
      }

      this.$store.commit('localization/translation/removeSelectedAttribute', attribute);
    },

    onTranslationsFetched(translations) {
      Object.entries(translations).forEach(translation => {
        const locale = translation[0];
        const attributes = Object.entries(translation[1]);

        let existingInputs = this.localizationCopy.find(lObj => lObj.language === locale).inputs;

        // Replace the text of each attribute input with the one returned from the backend.
        this.localizationCopy.find(lObj => lObj.language === locale).inputs = existingInputs.map(existingInput => {
          attributes.forEach(attr => {
            // attr[0] = attributeName, attr[1] = text
            // existingInput[0] = attributeName, existingInput[1] = text
            if (attr[0] === existingInput[0]) {
              existingInput[1] = attr[1];
            }
          });

          return existingInput;
        });
      });

      this.$sendSuccessToast('Traductions chargées. Veuillez relire et vérifier.');
    }
  },
  created() {
    this.$store.commit('localization/translation/clear');

    this.localizationCopy = Object.entries(this.localizations).map(l => {
      return {
        language: l[0],
        inputs: Object.entries(l[1])
      }
    });
  },
  computed: {
    getPayloadForSave() {
      let object = {};

      Object.entries(this.localizationCopy).forEach(inputList => {
        object[inputList[1].language] =
          Object
            .fromEntries(
              inputList[1]
                .inputs
                .filter(i => i[1] !== null && i[1].length)
            );
      });

      return object;
    },
    isTargetLanguageSelected() {
      return this.$store.getters['localization/translation/getSelectedLocale'] !== null;
    },
  }
}
</script>

