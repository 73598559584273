<template>
  <div>
    <page-title
      :document-page-title="`Marque | ID: ${getBrandId} | ${getBrandName}`"
    >
      #{{ getBrandId }} | {{ getBrandName }}
    </page-title>

    <card>
      <card-tab id="brandTab" key="brandTab" ref="brandTab" icon="cube" title="Marque">
        <create-or-update-brand-form
          mode="update"
        />
      </card-tab>

      <card-tab id="localizationTab" ref="localizationTab" icon="globe" title="Traduction">
        <update-brand-localization-form :brand-id="getBrandId" />
      </card-tab>
    </card>
  </div>
</template>

<script>
import CreateOrUpdateBrandForm from "../Forms/CreateOrUpdateBrandForm";
import CardTab from "@/components/elements/cards/tabs/CardTab.vue";
import Card from "@/components/elements/cards/Card.vue";
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import UpdateBrandLocalizationForm from "@/components/pages/Brands/Forms/Localization/UpdateBrandLocalizationForm.vue";

export default {
  name: "EditBrand",
  components: {UpdateBrandLocalizationForm, PageTitle, Card, CardTab, CreateOrUpdateBrandForm},
  computed: {
    getBrandId() {
      return parseInt(this.$route.params.brand);
    },
    getBrandName() {
      return this.$store.getters['brands/getBrandName'] || '[...]';
    },
  },
}
</script>