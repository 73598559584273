<template>
    <div>
        <custom-table :headings="getHeadings">
            <table-row v-for="(info,index) in provencaleOrderInfo" :key="index">
                <table-data-cell>
                    {{ info.cartId }}
                </table-data-cell>
                <table-data-cell >
                    {{ info.status }}
                </table-data-cell>
                <table-data-cell>
                    {{ $date(info.deliveryDate).format('DD.MM.YYYY HH:mm') }}
                </table-data-cell>
                <table-data-cell>
                    {{ info.articleCount }}
                </table-data-cell>
                <table-data-cell>
                    <money-amount :amount="info.amount"/>
                </table-data-cell>
            </table-row>
        </custom-table>
    </div>
</template>

<script>
import CustomTable from "@/components/elements/tables/CustomTable";
import TableRow from "@/components/elements/tables/TableRow";
import TableDataCell from "@/components/elements/tables/TableDataCell";
import SupplierOrderRepository from "../../../../../repositories/SupplierOrderRepository";
import MoneyAmount from "@/components/global/Money/MoneyAmount.vue";

export default {

    name: "ProvencaleOrderStatus",
    components: {MoneyAmount, TableDataCell, TableRow, CustomTable},
    props: {
        supplierOrderId: {
            type: Number,
            required: true
        }
    },

    data: () => ({
        provencaleOrderInfo: null,
    }),
    mounted() {
        SupplierOrderRepository.getProvencaleOrderInfo(this.supplierOrderId).then((r) => {
                this.provencaleOrderInfo = r.data.data;
            }
        );
    },

    computed: {
        getHeadings() {
            return [
                {label: 'CartId'},
                {label: 'Status'},
                {label: 'Livr. prevu'},
                {label: 'Nb.Items'},
                {label: 'Total TTC'},
            ];
        }
    }
}
</script>

