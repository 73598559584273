<template>
  <modal ref="modal" :blocking="true" title="Découper image" @close="onModalClose" @open="onModalOpen">
    <cropper
      v-if="renderCropper"
      :src="file.objectURL"
      class="cropper"
      @change="onChangeImage"
    />

    <template #footer>
      <div class="flex justify-end gap-x-2">
        <button-element type="primary" @click="confirmCrop">
          <fa-icon fixed-width icon="check"></fa-icon>
          Confirmer selection
        </button-element>
      </div>
    </template>
  </modal>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Modal from "@/components/elements/modals/Modal.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "FileUploadImageCardCropModal",
  components: {ButtonElement, Modal, Cropper},
  data() {
    return {
      renderCropper: false,
      croppedDataUrl: null,
    }
  },
  props: {
    file: {
      type: Object,
      required: true
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },

    onChangeImage({canvas}) {
      this.croppedDataUrl = canvas.toDataURL();
    },

    confirmCrop() {
      this.$emit('cropped', this.croppedDataUrl);
      this.$refs.modal.closeModal();
    },

    onModalOpen() {
      this.renderCropper = true;
      this.$emit('open');
    },
    onModalClose() {
      this.renderCropper = false;
      this.$emit('close');
    },

  },
}
</script>

<style scoped>
.cropper {
  height: 600px;
  width: 600px;
  background: #DDD;
}
</style>