import Vue from "vue";

const getDefaultState = () => {
  return {
    brand: null
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getBrandId: state => state.brand?.id,
    getBrandName: state => state.brand?.name,
  },
  mutations: {
    setBrand(state, brand) {
      Vue.set(state, 'brand', brand)
    }
  },
}