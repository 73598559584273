<template>
    <div>
        <select-element
                :enable-search="enableSearch"
                :label="label"
                :nothing-selected-text="nothingSelectedText"
                :occupy-validation-space="occupyValidationSpace"
                :options="getDeliveryContacts"
                :validation-rules="validationRules"
                :value="value"
                @input="onInput"
        />

        <transition name="fade-up-down">
            <card v-if="value && showSelectionInfo" :class="selectionCardClasses" class="border-0 mb-4" type="light">
                <div class="flex justify-between gap-x-12">

                    <div>
                        <span class="font-bold">{{ value.id }}</span><br>
                        <p>
                            {{ value.street }}<br>
                            {{ value.postalCode }} {{ value.city }}<br>
                            {{ value.country | toCountryNameString }}
                        </p>
                    </div>

                    <div class="bg-gray-100 flex flex-col items-center justify-center gap-4 py-2 px-8 rounded-xl">
                        <fa-icon icon="truck" size="2x"></fa-icon>
                        <h4 class="text-xl">Zone {{ value.zoneId }}</h4>
                    </div>

                </div>
            </card>

            <card v-if="!subscriptions.length && hasQueried" class="text-center" type="danger">
                Pas de subscription. Veuillez d'abord créer un subscription.
            </card>
        </transition>
    </div>
</template>

<script>
import Card from "../../cards/Card";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";
import SubscriptionRepository from "@/repositories/SubscriptionRepository";

export default {
    name: "ActiveSubscriptionSelectInput",
    components: {SelectElement, Card},
    data: () => ({
        subscriptions: [],
        hasQueried: false
    }),
    props: {
        value: {
            required: true
        },
        memberId: {
            type: Number,
            required: true
        },
        showSelectionInfo: {
            type: Boolean,
            default: false
        },
        selectionCardClasses: {
            type: String,
            required: false
        },
        enableSearch: {
            type: Boolean,
            default: false
        },
        label: {
            default: 'Subscription',
            required: false,
        },
        options: {
            required: false,
            validator: val => typeof val === "object"
        },
        validationRules: {
            type: String,
            required: false,
        },
        nothingSelectedText: {
            type: String,
        },
        occupyValidationSpace: {
            type: Boolean,
            default: true
        }
    },
    mounted() {
        this.fetchSubscriptions();
    },
    watch: {
        memberId: function () {
            this.$emit('input', null);
            this.fetchSubscriptions();
        }
    },
    methods: {
        fetchSubscriptions() {
            console.log('sss');
            SubscriptionRepository.listForMember(this.memberId).then((res) => {
                this.subscriptions = res.data.data;
                this.hasQueried = true;
            })
        },
        onInput(e) {
            this.$emit('input', e);
        }
    },
    computed: {
        getDeliveryContacts() {
            return this.subscriptions.map(c => {
                if (!c.isActive) {
                    return ;
                }
                return {
                    label: '#Subscription' + c.id + ': ' + c.street + ' ' + c.postalCode + ' ' + c.city + ' ' + c.country.toUpperCase() + ' (Zone ' + (c.zoneId || '???') + ')',
                    value: c
                };
            })
        },
    },
}
</script>

