<template>
  <div>
    <list-table
      ref="table"
      :columns="columns"
      :create-button="false"
      :pagination="false"
      :sent-table-rows="orders"
      :show-refresh-button="false"
      disable-clear-batch-errors-on-mount
      identifier="productPackagingOrderTable"
      sub-key="today"
      @loaded="onTableRowsLoaded"
    >
      <template #title>
        <div class="flex mb-1 space-x-2 text-center items-center font-bold">
          <div class="text-lg w-14 py-0.5 bg-luxcaddy-400 shadow text-white rounded">
            {{ amount }}
          </div>
          <div class="text-gray-500">{{ title }}</div>
        </div>
      </template>

      <template v-slot:supplier-order-id-template="{ row }">
        <text-link
          :to="{name: 'supplier-orders.edit', params: { order: row.supplierOrderId }}"
        >
          {{ row.supplierOrderId }}
        </text-link>
      </template>

    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import TextLink from "@/components/global/TextLink.vue";

export default {
  name: "ProductPackagingOrderTable",
  components: {TextLink, ListTable},
  props: {
    productId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      amount: 0,

      selectValues: product => {
        return {
          orderId: product.orderId
        };
      },

      columns: [
        {
          caption: '#',
          cellTemplate: 'supplier-order-id-template'
        },
        {
          caption: 'Fournisseur',
          dataField: 'name'
        },
        {
          caption: 'Lots commandés',
          dataField: 'orderedBuyingBundleQuantity'
        },
        {
          caption: 'Unitées commandées',
          dataField: 'orderedSellingBundleQuantity'
        },
        {
          caption: 'Date commande',
          calculateCellValue: (r) => {
            return r.orderDate === null
              ? ""
              : this.$date(r.orderDate).format('DD.MM.YYYY HH:mm');
          },
        },
        {
          caption: 'Date envoyé',
          calculateCellValue: (r) => {
            return r.orderDate === null
              ? ""
              : this.$date(r.orderDate).format('DD.MM.YYYY HH:mm');
          },
        },
        {
          caption: 'Livr. prévu: ',
          calculateCellValue: (r) => {
            return r.expectedDeliveryDate === null
              ? ""
              : this.$date(r.expectedDeliveryDate).format('DD.MM.YYYY HH:mm');
          },
        },
      ]
    };
  },
  methods: {
    onTableRowsLoaded(rows) {
      this.amount = rows.reduce((n, {orderedSellingBundleQuantity}) => n + orderedSellingBundleQuantity, 0);
    }
  },
}
</script>

<style scoped>

</style>
