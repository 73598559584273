<template>
  <div>
    <page-title
      document-page-title="Créer commande"
    >
      Créer commande
    </page-title>

    <card>
        <template>
            <h3 class="font-bold mb-2">
                Type de commande
            </h3>

            <div class="flex gap-x-4 mb-6">
                <div
                        :class="[orderType === 'luxcaddy' ? 'border-luxcaddy-500 bg-luxcaddy-300': 'hover:bg-luxcaddy-200']"
                        class="bg-luxcaddy-100 rounded-md p-16 cursor-pointer border-2 border-dashed border-transparent"
                        @click="orderType = 'luxcaddy'"
                >
                    LUXCADDY
                </div>
                <div
                        :class="[orderType === 'freshly' ? 'border-luxcaddy-500 bg-luxcaddy-300': 'hover:bg-luxcaddy-200']"
                        class="bg-luxcaddy-100 rounded-md p-16 cursor-pointer border-2 border-dashed border-transparent"
                        @click="orderType = 'freshly'"
                >
                    FRESHLY
                </div>
            </div>
        </template>
        <template v-if="orderType === 'luxcaddy'">
            <create-luxcaddy-order @created="onOrderCreate"/>
        </template>

        <template v-if="orderType === 'freshly'">
            <create-freshly-order  @created="onOrderCreate"/>
        </template>

    </card>
  </div>
</template>

<script>
import CreateLuxcaddyOrder from "@/components/pages/Orders/CreateOrder/CreateLuxcaddyOrder.vue";
import CreateFreshlyOrder from "@/components/pages/Orders/CreateOrder/CreateFreshlyOrder.vue";
import Card from "@/components/elements/cards/Card.vue";
import PageTitle from "@/components/elements/pages/PageTitle.vue";

export default {
  name: "CreateOrder",
  components: {
      Card,
      CreateFreshlyOrder,
      CreateLuxcaddyOrder,
      PageTitle
  },
  data: () => ({
      orderType: 'luxcaddy'
  }),
  methods: {
      onOrderCreate(orderId) {
          this.$sendSuccessToast("Command crée!");

          this.$router.push({
              name: 'orders.edit',
              params: {
                  order: orderId
              }
          });
      },
  },
}
</script>
