<template>
  <div v-if="hasOrder">
    <div class="flex justify-between mb-4">

      <div class="flex items-center gap-x-4">
        <page-title without-margin>
          {{ getOrder.supplierName }} - {{ $date(getOrder.createdAt).format('DD.MM.YYYY HH:mm') }}
          #{{ getOrder.id }}
        </page-title>

        <supplier-order-status
          :finalized="!getOrder.isDraft"
        />
      </div>

      <div class="flex gap-x-2">
        <button-element
          v-if="getOrder && isFinalized"
          type="dark"
          @click="downloadOrderPdf"
        >
          <fa-icon class="mr-2" fixed-width icon="file-pdf"/>
          Télécharger PDF
        </button-element>

        <supplier-order-finalize-button v-if="getOrder && !isFinalized && !$isReadOnly()"/>

        <batch-actions-single-model
          v-if="getOrder && !$isReadOnly()"
          :batch-actions="SupplierOrderBatchActions.batchActions"
          :selected-value="SupplierOrderBatchActions.selectValuesFromId($route.params.order)"
          @executed="fetchOrder($route.params.order)"
        />
      </div>
    </div>

    <card class="mb-4" size="small">
      <card-tab id="orderSettings" icon="cogs" title="Commande">

        <div class="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-4">
          <card :class="isFinalized && getInvoiceNumber  ? 'lg:col-span-3' : 'lg:col-span-4'" size="small"
                type="primary">
            <div v-if="getOrder !== null" class="flex flex-wrap gap-x-8 gap-y-3">
              <div>
                <span class="font-bold">Fournisseur:</span> {{ getOrder.supplierName }}
              </div>
              <div>
                <span class="font-bold">Date commande:</span>
                {{ $date(getOrder.createdAt).format('DD.MM.YYYY HH:mm') }}
              </div>
              <div>
                <span class="font-bold">Date de livraison prévu:</span>
                {{ $date(getOrder.expectedDeliveryDate).format('DD.MM.YYYY HH:mm') }}
              </div>
              <div>
                <span class="font-bold">Date d'envoi:</span>
                {{
                  getOrder.sentAt === null ? 'Pas encore envoyé' : $date(getOrder.sentAt).format('DD.MM.YYYY HH:mm')
                }}
              </div>
            </div>

            <div
              v-if="getOrder !== null"
              class="flex gap-x-8 mt-8"
            >
              <div>
                <span class="font-bold">Commande minimal:</span>
                {{ centsToEuroString(getOrder.supplierMinOrder) }}
              </div>
              <div
                :class="hasOrderAmountReachedMinAmount ? 'text-luxcaddy-600' : 'text-red-500'"
              >
                <span class="font-bold">Total Commande:</span>
                {{ centsToEuroString(getOrder.total.price.amount) }}
              </div>
            </div>
          </card>

          <supplier-order-invoice-card v-if="isFinalized && getInvoiceNumber"/>
        </div>

        <card
          v-if="isDraft"
          :navigation-through-query-params="false"
          class="mb-4"
          size="small"
          type="light"
        >
          <card-tab
            id="addProduct"
            icon="plus"
            title="Ajouter produit"
          >
            <checkbox-element
              v-model="isInAddProductForeMode"
              label="Forcer"
            />

            <product-search-selection
              :filter-by-supplier-id="getOrder.supplierMemberId"
              :show-quantity-selector="false"
              context="supplierOrder"
              @added="fetchAndReRender"
            />
          </card-tab>

          <card-tab
            id="suggestions"
            title="Suggestions"
          >
            <supplier-order-suggestions-table
              @changed="reRenderList"
            />
          </card-tab>
        </card>

        <supplier-order-item-list
          v-if="renderList"
        />

      </card-tab>

      <card-tab
        v-if="$store.getters['supplierOrders/hasLinkedInvoicePDFIds']"
        id="orderInvoicePDFs"
        icon="file-invoice"
        title="PDFs factures"
      >
        <supplier-order-invoice-pdf-list/>
      </card-tab>

      <card-tab v-if="hasFiles" id="orderFiles" icon="folder-open" title="Fichiers">
        <supplier-order-file-list/>
      </card-tab>

      <card-tab v-if="hasDeliveryNote" id="orderDeliveryCheck" icon="list-ul" title="Livraison Check">
        <provencale-delivery-check :supplierOrderId="getOrder.id"/>
      </card-tab>

      <card-tab v-if="isApiProvencale" id="orderStatusCheck" icon="list-ul" title="Status Check">
          <provencale-order-status :supplierOrderId="getOrder.id" > </provencale-order-status>
      </card-tab>


      <card-tab id="orderLogs" icon="file-alt" title="Logs">

        <show-logs-table
          v-if="getOrder"
          :default-loggable-id="getOrder.id"
          default-loggable-type="SupplierOrder"
        />
      </card-tab>


    </card>


  </div>
</template>

<script>
import Card from "@/components/elements/cards/Card";
import PageTitle from "@/components/elements/pages/PageTitle";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SupplierOrderItemList from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/SupplierOrderItemList";
import SupplierOrderFileList from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/SupplierOrderFileList";
import ProvencaleDeliveryCheck
  from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/ProvencaleDeliveryCheck";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import ProductSearchSelection from "@/components/global/Products/ProductSearchSelection";
import SupplierOrderFinalizeButton
  from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/SupplierOrderFinalizeButton";
import SupplierOrderInvoiceCard
  from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/SupplierOrderInvoiceCard";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import ShowLogsTable from "@/components/pages/Logs/ShowLogsTable";
import SupplierOrderStatus from "@/components/pages/SupplierOrders/Status/SupplierOrderStatus";
import SupplierOrderSuggestionsTable
  from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/SupplierOrderSuggestionsTable.vue";
import SupplierOrderRepository from "@/repositories/SupplierOrderRepository";
import {exportResponse} from "@/helpers/exportHelper";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement.vue";
import {centsToEuroString} from "../../../../helpers/mathHelper";
import BatchActionsSingleModel from "@/batchAction/components/BatchActionsSingleModel.vue";
import SupplierOrderBatchActions from "@/batchAction/supplierOrderBatchActions";
import SupplierOrderInvoicePdfList
  from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/InvoicePDFs/SupplierOrderInvoicePdfList.vue";
import ProvencaleOrderStatus
    from "@/components/pages/SupplierOrders/EditSupplierOrder/Includes/ProvencaleOrderStatus.vue";

export default {
  name: "EditSupplierOrder",
  components: {
      ProvencaleOrderStatus,
    SupplierOrderInvoicePdfList,
    BatchActionsSingleModel,
    CheckboxElement,
    SupplierOrderSuggestionsTable,
    SupplierOrderStatus,
    ShowLogsTable,
    CardTab,
    SupplierOrderInvoiceCard,
    SupplierOrderFinalizeButton,
    ProductSearchSelection,
    ButtonElement,
    SupplierOrderItemList,
    SupplierOrderFileList,
    ProvencaleDeliveryCheck,
    PageTitle,
    Card
  },
  created() {
    this.setIsInAddProductForceMode(false);
    this.fetchOrder(this.$route.params.order);
  },
  beforeDestroy() {
    this.clearOrder();
  },
  data: () => ({
    renderList: true,
  }),
  methods: {
    centsToEuroString,
    ...mapActions('supplierOrders', [
      'fetchOrder',
      'fetchOrderProductSuggestions'
    ]),
    ...mapMutations('supplierOrders', [
      'clearOrder',
      'setBatchErrors',
      'clearReceivedItemValues',
      'setIsInAddProductForceMode',
    ]),
    downloadOrderPdf() {
      if (!this.getOrder || !this.isFinalized) {
        return false;
      }

      return SupplierOrderRepository
        .getOrderPDF(this.getOrder.id)
        .then(res => {
          exportResponse(res, `commande-fournisseur-${this.getOrder.id}`, 'pdf');
        });
    },
    fetchAndReRender() {
      this.fetchOrder(this.$route.params.order).finally(() => {
        this.reRenderList();
      })
    },
    reRenderList() {
      this.renderList = false;
      this.$nextTick(() => {
        this.renderList = true;
      })
    }
  },
  computed: {
    SupplierOrderBatchActions() {
      return SupplierOrderBatchActions
    },
    ...mapGetters('supplierOrders', [
      'getOrder',
      'hasOrder',
      'hasReceivedInvoice',
      'getInvoiceNumber'
    ]),
    isInAddProductForeMode: {
      get() {
        return this.$store.getters["supplierOrders/getIsInAddProductForceMode"];
      },
      set(value) {
        this.$store.commit('supplierOrders/setIsInAddProductForceMode', value);
      }
    },
    isDraft() {
      return this.getOrder?.isDraft;
    },
    isFinalized() {
      return !this.isDraft;
    },
    hasFiles() {
      return this.getOrder?.files?.length > 0 || false;
    },
    hasDeliveryNote() {
      if (!this.hasFiles) {
        return false;
      }

      return this.getOrder.files.some(e => e.type === 'BL')
    },

    isApiProvencale() {

      return this.getOrder.isProvencale && this.getOrder.isApiGeneratedByCustomerOrder;
    },
    hasOrderAmountReachedMinAmount() {
      if (!this.getOrder) {
        return false;
      }

      return this.getOrder.total.price.amount >= this.getOrder.supplierMinOrder;
    }
  },
}
</script>

