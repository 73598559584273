import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import appStore from "@/store/modules/appStore/appStore";
import userInterfaceStore from "@/store/modules/userInterface/userInterfaceStore";
import loadingStore from "@/store/modules/userInterface/loadingStore";
import categoriesStore from "@/store/modules/categories/categoriesStore";
import cartStore from "@/store/modules/cart/cartStore";
import globalStore from "@/store/modules/global/globalStore";
import orderStore from "@/store/modules/order/orderStore";
import formsStore from "@/store/modules/forms/formsStore";
import feeStore from "@/store/modules/fees/feeStore";
import deliveryTimeSlotStore from "@/store/modules/deliveryTimeSlots/deliveryTimeSlotStore";
import rackStore from "@/store/modules/racks/rackStore";
import listTableStore from "@/store/modules/listTable/listTableStore";
import campaignStore from "@/store/modules/campaigns/campaignStore";
import fulfilmentStore from "@/store/modules/fulfilment/fulfilmentStore";
import fulfilmentFilterStore from "@/store/modules/fulfilment/fulfilmentFilterStore";
import routeManagerStore from "@/store/modules/routeManager/routeManagerStore";
import invoiceStore from "@/store/modules/invoice/invoiceStore";
import supplierOrderStore from "@/store/modules/supplierOrder/supplierOrderStore";
import lockScreenStore from "@/store/modules/fulfilment/lockScreenStore";
import authUserStore from "@/store/modules/auth/authUserStore";
import notificationStore from "@/store/modules/userInterface/notificationStore";
import bannerStore from "@/store/modules/banners/bannerStore";
import supplierOrderPreviewStore from "@/store/modules/supplierOrder/supplierOrderPreviewStore";
import productStore from "@/store/modules/products/productStore";
import fruitsVegetablesInterfaceStore from "@/store/modules/fulfilment/fruitsVegetablesInterfaceStore";
import bankMovementStore from "@/store/modules/accounting/bankMovementStore";
import localizationTranslationStore from "@/store/modules/localization/localizationTranslationStore";
import listTableFiltersStore from "@/store/modules/listTable/listTableFiltersStore";
import routeManagerAutomaticPlanningStore
  from "@/store/modules/routeManager/automaticPlanning/routeManagerAutomaticPlanningStore"
import reportQueryStore from "@/store/modules/reportQuery/reportQueryStore";
import TouchInterfaceHolidaysStore from "@/store/modules/touchInterface/holidays/TouchInterfaceHolidaysStore";
import TouchInterfaceDriverDeliveriesStore
  from "@/store/modules/touchInterface/drivers/deliveries/TouchInterfaceDriverDeliveriesStore";
import TouchInterfaceUiStore from "@/store/modules/touchInterface/userInterface/touchInterfaceUiStore";
import commentsStore from "@/store/modules/comments/commentsStore";
import reportQueryGraphStore from "@/store/modules/graphs/ReportQueryGraphStore";
import brandStore from "@/store/modules/brands/brandStore";

Vue.use(Vuex);


const store = new Vuex.Store({
  modules: {
    app: {namespaced: true, ...appStore},
    global: {namespaced: true, ...globalStore},
    authUser: {namespaced: true, ...authUserStore},
    forms: {namespaced: true, ...formsStore},
    userInterface: {namespaced: true, ...userInterfaceStore},
    notifications: {namespaced: true, ...notificationStore},
    loading: {namespaced: true, ...loadingStore},
    categories: {namespaced: true, ...categoriesStore},
    cart: {namespaced: true, ...cartStore},
    order: {namespaced: true, ...orderStore},
    fees: {namespaced: true, ...feeStore},
    deliveryTimeSlots: {namespaced: true, ...deliveryTimeSlotStore},
    racks: {namespaced: true, ...rackStore},
    comments: {namespaced: true, ...commentsStore},
    listTable: {
      namespaced: true,
      ...listTableStore,
      modules: {
        filters: {namespaced: true, ...listTableFiltersStore}
      }
    },
    campaigns: {namespaced: true, ...campaignStore},
    invoices: {namespaced: true, ...invoiceStore},
    supplierOrders: {namespaced: true, ...supplierOrderStore},
    banners: {namespaced: true, ...bannerStore},
    supplierOrderPreview: {namespaced: true, ...supplierOrderPreviewStore},
    products: {namespaced: true, ...productStore},
    brands: {namespaced: true, ...brandStore},
    bankMovements: {namespaced: true, ...bankMovementStore},
    reportQuery: {namespaced: true, ...reportQueryStore},
    reportQueryGraphs: {namespaced: true, ...reportQueryGraphStore},
    localization: {
      namespaced: true,
      modules: {
        translation: {namespaced: true, ...localizationTranslationStore}
      }
    },
    routeManager: {
      namespaced: true,
      ...routeManagerStore,
      modules: {
        automaticPlanning: {namespaced: true, ...routeManagerAutomaticPlanningStore}
      }
    },

    fulfilment: {namespaced: true, ...fulfilmentStore},
    fulfilmentFilters: {namespaced: true, ...fulfilmentFilterStore},
    lockScreen: {namespaced: true, ...lockScreenStore,},
    fruitsVegetablesInterface: {namespaced: true, ...fruitsVegetablesInterfaceStore},


    touchInterface: {
      namespaced: true,
      modules: {
        ui: {namespaced: true, ...TouchInterfaceUiStore},
        myHolidays: {namespaced: true, ...TouchInterfaceHolidaysStore},
        drivers: {
          namespaced: true,
          modules: {
            deliveries: {namespaced: true, ...TouchInterfaceDriverDeliveriesStore},
          }
        }
      }
    },
  },
});

export default store;
