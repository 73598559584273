<template>
    <div class="grid grid-cols-2 gap-x-4 relative">
        <div class= "col-span-2">
          <card>
            <form-wrapper
                    ref="form"
                    show-changed
                    @submit="createOrder"
            >
                <member-select-input
                        v-model="memberId"
                        :only-member-types="['customer']"
                        label="Client"
                        only-active-member-types
                        validation-rules="required"
                />

                <checkbox-element
                        v-if="memberId !== null"
                        v-model="shouldCreateInvoice"
                        label="Créer aussi une facture"
                />

                <checkbox-element
                        v-if="memberId !== null && shouldCreateInvoice"
                        v-model="monthlyInvoice"
                        label="Facture mensuelle?"
                />
                <checkbox-element
                        v-if="memberId !== null"
                        v-model="shouldCreateDelivery"
                        label="Créer aussi une livraison"
                />

                <template
                        v-if="shouldCreateDelivery"
                >
                    <delivery-contact-select-input
                            v-model="deliveryContact"
                            :member-id="memberId"
                            validation-rules="required"
                    />

                    <time-slot-selection
                            v-if="deliveryContact"
                            v-model="deliveryTimeSlotId"
                            :zone-id="deliveryContact.zoneId"
                    />
                </template>
            </form-wrapper>
        </card>
        </div>
    </div>
</template>

<script>
import Card from "@/components/elements/cards/Card.vue";
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput.vue";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement.vue";
import TimeSlotSelection from "@/components/global/TimeSlots/TimeSlotSelection.vue";
import DeliveryContactSelectInput from "@/components/elements/forms/elements/DeliveryContactSelectInput.vue";
import OrderRepository from "@/repositories/OrderRepository";

export default {
    name: "CreateLuxcaddyOrder",
    components: {
        DeliveryContactSelectInput,
        TimeSlotSelection, CheckboxElement, MemberSelectInput, FormWrapper, Card
    },
    data() {
        return {
            memberId: null,
            shouldCreateDelivery: false,
            shouldCreateInvoice: true,
            monthlyInvoice: false,
            deliveryContact: null,
            deliveryTimeSlotId: null,
        }
    },
    methods: {
        createOrder(callback) {
            let payload = {
                memberId: this.memberId,
                shouldCreateDelivery: this.shouldCreateDelivery,
                shouldCreateInvoice: this.shouldCreateInvoice,
                monthlyInvoice: this.monthlyInvoice
            };

            if (this.shouldCreateDelivery) {
                payload.deliveryContactId = this.getDeliveryContactId;
                payload.deliveryTimeSlotId = this.deliveryTimeSlotId;
            }

            return OrderRepository
                .createOrder(payload)
                .then((res) => {
                    this.$emit('created', res.data.data.id);

                    this.$router.push({
                        name: 'orders.edit',
                        params: {
                            order: res.data.data.id
                        }
                    })
                })
                .finally(() => callback());
        }
    },
    computed: {
        getDeliveryContactId() {
            return this.deliveryContact?.id || null;
        }
    },
}
</script>

<style scoped>

</style>