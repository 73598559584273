<template>
  <div>
      <button-element
              class="mb-4"
              @click="toggleShowImportCard"
      >
         Upload Products
      </button-element>
    <import-products
            v-if="showImportCard"
            class="mb-6"
            @change="onFileUploadDone"
    />
    <card class="p-4 mb-4" v-if="importSuccessMessage" v-html="importSuccessMessage">

    </card>

    <list-table
      :key = productsTableKey
      :batch-actions="ProductBatchActions.batchActions"
      :columns="columns"
      :create-route="{
                name: 'products.create'
            }"
      :exportButton="true"
      :filters="filters"
      :pagination="true"
      :repository-export-func="repositoryExportFunc"
      :repository-func="repositoryFunc"
      :select-values="ProductBatchActions.selectValues"
      identifier="productsTable"
      selectable
      show-filters-immediately
      title="Produits"
    >

      <template v-slot:product-quick-action-template="{ row }">
        <div class="flex md:flex-row gap-y-4 justify-between">
          <div>
            <product-quick-action-modal :id="row.id"/>
          </div>
          <div class="w-full md:p-0 md:w-6 flex md:items-center md:justify-center">
            <fa-icon v-if="row.hasCampaigns && row.hasOneProductCampaign" icon="gift"
                     style="color:green"></fa-icon>
            <fa-icon v-else-if="row.hasCampaigns && !row.hasOneProductCampaign" icon="gifts"
                     style="color:green"></fa-icon>
          </div>
        </div>

      </template>

      <template v-slot:product-name-template="{ row }">
        <product-name :product="row"/>
      </template>

      <template v-slot:rack-template="{ row }">
        <stock-rack
          :rack-number="row.pickingConfiguration.stockAddressRack"
          class="w-min"
        />
      </template>

    </list-table>
  </div>
</template>

<script>
import ProductRepository from "../../../repositories/ProductRepository";
import ProductQuickActionModal from "./Subcomponents/ProductQuickActionModal";
import ListTable from "../../listTable/ListTable";

import {productFilters} from "@/helpers/listTableModels/ListTableProductFilters";
import StockRack from "@/components/global/StockRack/StockRack";

import ProductBatchActions from "@/batchAction/productBatchActions";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";
import Card from "@/components/elements/cards/Card.vue";
import ImportProducts from "@/components/pages/Products/Import/ImportProducts.vue";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "ShowProducts",
  components: {ButtonElement, ImportProducts, Card, ProductName, StockRack, ListTable, ProductQuickActionModal},
  methods: {
    toggleShowImportCard() {
      this.showImportCard = !this.showImportCard;
      this.importSuccessMessage = null;
    },
    onFileUploadDone(messageStr) {

        this.showImportCard = false;
        this.importSuccessMessage = messageStr;
        this.productsTableKey++;
    },
    duplicateProduct(id) {
      return ProductRepository
        .duplicate(id)
        .then((res) => {
          this.$router.push({name: 'products.edit', params: {product: res.data.data.id}});
        });
    }
  },
  computed: {
    ProductBatchActions() {
      return ProductBatchActions;
    },
  },
  data() {
    return {
      productsTableKey:0,
      showImportCard: false,
      importSuccessMessage: null,
      brands: [],
      title: 'Produits',
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir produit',
              route: (row) => {
                return {
                  name: 'products.edit',
                  params: {
                    product: row.id,
                  },
                  query: {readonly: true}
                };
              },
            },
            {
              type: 'edit',
              hint: 'Modifier produit',
              route: (row) => {
                return {
                  name: 'products.edit',
                  params: {
                    product: row.id
                  }
                };
              }
            },
            {
              type: 'delete',
              hint: 'Duplicate',
              icon: 'copy',
              deleteAction: (row) => this.duplicateProduct(row.id),
              confirmation: {
                title: 'Dupliquer le produit?',
                text: 'Étes vous sure que vous voulez dupliquer ce produit?'
              },
            },
            {
              type: 'delete',
              hint: 'Abandonner',
              condition: (row) => !row.isDiscontinued,
              confirmation: {
                title: 'Abandonner produit?',
                text: 'Étes vous sure que vous voulez abandonner ce produit?'
              },
              successText: 'Le produit a été abandonné',
              deleteAction: (row) => ProductRepository.discontinue(row.id),
              deletePermissions: ['PRODUCT_WRITE']
            },
            {
              type: 'delete',
              hint: 'Reactiver',
              icon: 'check',
              condition: (row) => row.isDiscontinued,
              confirmation: {
                title: 'Reactiver produit?',
                text: 'Étes vous sure que vous voulez reactiver ce produit qui a été abandonné?'
              },
              successText: 'Le produit a été reactivé',
              deleteAction: (row) => ProductRepository.undiscontinue(row.id),
              deletePermissions: ['PRODUCT_WRITE']
            },
          ],
        },
        {
          caption: '#Random',
          dataField: 'id',
          sort: {
            dataField: 'random'
          },
          visible: false,
        },
        {
          caption: '#',
          width: 80,
          dataField: 'id',
          cellTemplate: "product-quick-action-template",
          sort: {
            dataField: 'id',
            default: 'desc'
          }
        },
        {
          caption: 'Image',
          dataType: "image",
          visible: false,
          calculateCellValue: (r) => {
            return r.images?.image?.primary[0]?.formats?.find(f => f.size === "small")?.url || null;
          }
        },
        {
          caption: 'Contenu',
          calculateCellValue: (r) => {
            let sdB = r.sellingData.bundle;
            return sdB.quantity + 'x ' + sdB.content + sdB.unit;
          }
        },
        {
          caption: 'Identificateur',
          cellTemplate: "product-name-template",
          sort: {
            dataField: 'name'
          }
        },
        {
          caption: 'Marque',
          dataField: 'brand.name',
          sort: {
            dataField: 'brand'
          }
        },
        {
          caption: 'Référence fournisseur',
          dataField: 'supplier.reference',
          visible: true,
          sort: {
            dataField: 'supplierReference'
          },
        },

        {
          caption: 'StD',
          dataField: 'stock.values.available',
        },
        {
          caption: 'StR',
          dataField: 'stock.values.real',
          sort: {
            dataField: 'stockReal'
          }
        },
        {
          caption: 'Peut être acheté',
          dataField: 'canBeBought',
          dataType: 'boolean',
            calculateCellValue: (r) => r.canBeBought,

            visible: false
        },
        {
          caption: 'Coeff. Auto Stock',
          dataField: 'stock.configuration.autoStockCoefficientWithDefault',
          visible: false
        },
        {
          caption: 'Prix A.',
          calculateCellValue: (row) => {
            if (row.buyingData === undefined)
              return null;

            return (row.buyingData.price / 100).toFixed(2) + '€'
          },
          sort: {
            dataField: 'buyingPrice'
          }
        },
        {
          caption: 'Prix A. Unitaire',
          calculateCellValue: (row) => {
            if (row.buyingData === undefined)
              return null;

            return ((row.buyingData.price / row.buyingData.bundleQuantity) / 100).toFixed(2) + '€'
          },

        },
        {
          caption: 'Lot',
          dataField: 'buyingData.bundleQuantity',
          visible: false,

        },
        {
          caption: 'Prix V.',
          calculateCellValue: (row) => {
            if (row.sellingData === undefined)
              return null;

            return (row.sellingData.price / 100).toFixed(2) + '€'
          },
          sort: {
            dataField: 'sellingPrice'
          },
          visible: false,
        },
        {
          caption: 'Marge',
          calculateCellValue: (row) => {
            if (row.buyingData === undefined)
              return null;
            return row.buyingData.margin + '%'
          },
          visible: false,
          sort: {
            dataField: 'margin'
          },
        },
        {
          caption: 'Marge avec 10% remise',
          calculateCellValue: r => r.margin10 + '%',
          additionalClasses: (row) => {
            return row.margin10 < 0 ? 'text-red-500' : '';
          },
          visible: false,
        },
        {
          caption: 'Marge avec 20% remise',
          calculateCellValue: r => r.margin20 + '%',
          additionalClasses: (row) => {
            return row.margin20 < 0 ? 'text-red-500' : '';
          },
          visible: false,
        },
        {
          caption: 'Marge avec 30% remise',
          calculateCellValue: r => r.margin30 + '%',
          additionalClasses: (row) => {
            return row.margin30 < 0 ? 'text-red-500' : '';
          },
          visible: false,
        },
        {
          caption: 'Marge avec 40% remise',
          calculateCellValue: r => r.margin40 + '%',
          additionalClasses: (row) => {
            return row.margin40 < 0 ? 'text-red-500' : '';
          },
          visible: false,
        },
        {
          caption: 'Empl.',
          visible: false,
          sort: {
            dataField: 'rack'
          },
          cellTemplate: 'rack-template',
        },
        {
          caption: 'Publié le',
          calculateCellValue: (r) => this.$date(r.publishDate).format('DD.MM.YYYY'),
          visible: false,
          sort: {
            dataField: 'publishDate'
          },
        },
        {
          caption: 'Codes barres',
          calculateCellValue: (r) => r.barcodes === null ? '' : r.barcodes.replaceAll('|', '<br>'),
          visible: false,
        },
        {
          caption: 'TVA',
          calculateCellValue: (r) => r.sellingData.vat + '%',
          sort: {
            dataField: 'sellingVat'
          }
        },
        {
          caption: 'Publié',
          dataType: 'boolean',
          dataField: 'isPublished',
          calculateCellValue: (r) => r.isPublished,
        },
        {
          caption: 'Visibilité forcée',
          dataType: 'boolean',
          dataField: 'isShopVisibilityForced',
          calculateCellValue: (r) => r.isShopVisibilityForced,
          visible:false
        },
        {
          caption: 'Saison',
          calculateCellValue: (r) => {
            if (r.season === null || r.season === undefined || r.season.startMonth === null) {
              return "/";
            }
            let s = r.season;
            return `${s.startDay}.${s.startMonth} - ${s.endDay}.${s.endMonth}`;
          },
          visible: false
        },
        {
          caption: 'Fournisseur',
          dataField: 'supplier.name',
          visible: false,
          sort: {
            dataField: 'supplier'
          }
        },
      ],
      filters: productFilters,
      repositoryFunc: ProductRepository.getPaginated,
      repositoryExportFunc: ProductRepository.getExport,
    }
  },
}
</script>


