import { render, staticRenderFns } from "./CampaignSalesStats.vue?vue&type=template&id=108ba28f&scoped=true&"
import script from "./CampaignSalesStats.vue?vue&type=script&lang=js&"
export * from "./CampaignSalesStats.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108ba28f",
  null
  
)

export default component.exports