<template>
  <div class="group bg-gray-100 rounded-md p-4">
    <file-upload-image-card-crop-modal
      ref="modalComponent"
      :file="file"
      @cropped="onImageCropped"
    />

    <article
      class="relative w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent  shadow-sm h-32"
      tabindex="0"
    >

      <div
        class="absolute top-0 h-12 w-full opacity-0 group-hover:opacity-80 transition duration-200 rounded-md z-30 bg-gradient-to-b from-black"/>
      <div
        class="absolute bottom-0 h-12 w-full opacity-0 group-hover:opacity-80 transition duration-200 rounded-md z-30 bg-gradient-to-t from-black"/>

      <img
        :src="getUsedFileUrl"
        alt="Upload preview"
        class="w-full h-56 sticky object-contain shadow-lg z-0 rounded-md bg-fixed"
      />

      <section
        class="flex flex-col rounded-md text-xs break-words w-full h-full z-40 absolute top-0 transition duration-200 py-2 px-3 select-none"
      >

        <div class="p-1 text-sm font-bold text-center group-hover:text-white">
          {{ file.file.name }}
        </div>

        <div class="flex mt-auto group-hover:text-white">
          <span class="p-0.5">
            <fa-icon fixed-width icon="image"></fa-icon>
          </span>
          <p class="p-1 text-xs">{{ getHumanReadableSize(file.file.size) }}</p>


          <div class="flex group-hover:text-white ml-auto gap-x-2 items-center">
            <div
              class="ml-auto focus:outline-none group-hover:bg-luxcaddy-500 hover:text-luxcaddy-800 p-1 px-2 rounded-md"
              @click="openCropModal">
              <fa-icon fixed-width icon="crop"/>
              Decouper
            </div>
            <div
              class="ml-auto focus:outline-none group-hover:bg-red-500 hover:text-red-800 p-1 rounded-md"
              @click="removeFile">
              <fa-icon fixed-width icon="trash"/>
            </div>
          </div>
        </div>

      </section>
    </article>

    <div class="mt-8">
      <select-element
        v-if="availableLabels.length" :id="'image_type_' + file.objectURL"
        v-model="file.label"
        :allow-unselecting="false" :enable-search="false"
        :options="availableLabels" label="Type" validation-rules="required"></select-element>

      <select-element
        v-if="enableMediaLocalization" v-model="file.localization"
        :allow-unselecting="false"
        :enable-search="false" :options="availableLanguages" label="Langue"></select-element>
    </div>
  </div>
</template>

<script>
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";
import {getHumanReadableSize} from "@/helpers/fileHelper";
import FileUploadImageCardCropModal
  from "@/components/elements/forms/elements/base/fileUpload/FileUploadImageCardCropModal.vue";


export default {
  name: "FileUploadImageCard",
  components: {FileUploadImageCardCropModal, SelectElement},
  data() {
    return {
      showCropper: false,
      croppedDataUrl: null
    }
  },
  props: {
    // A file object which format's defined thriough "FileInputElement" component
    file: {
      type: Object,
      required: true
    },
    enableMediaLocalization: {
      type: Boolean,
    },
    availableLabels: {
      type: Array,
      default: () => ([])
    },
    availableLanguages: {
      type: Array,
      default: () => ([])
    },
  },
  methods: {
    getHumanReadableSize,

    openCropModal() {
      this.$refs.modalComponent.openModal();
    },

    removeFile() {
      this.$emit('remove', this.file.objectURL);
    },

    onImageCropped(dataUrl) {
      this.croppedDataUrl = dataUrl;
      this.$emit('file-url-change', this.getUsedFileUrl);
    },
  },

  computed: {
    getUsedFileUrl() {
      return this.croppedDataUrl
        ? this.croppedDataUrl
        : this.file.objectURL;
    }
  }

}
</script>

<style scoped>

</style>