import apiService from './Base/BaseRepository';

const url = '/supplierOrders';


export default {
  async get() {
    return await apiService.get(`${url}/`);
  },

  async getFile(fileId) {
    return await apiService.get(`${url}/file/${fileId}`);
  },

  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async getPaginatedWithIncomeForToday(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    let hasWaitForJITFilterSet = filters.find(f => f.filterKey === "waitingForJustInTimeIncomeForToday") !== undefined;
    if (!hasWaitForJITFilterSet) {
      filters.push({
        filterKey: "waitingForJustInTimeIncomeForToday",
        filterValue: 1,
      });
    }

    return await apiService
      .setSorts(sorts)
      .setPagination(perPage, page, withTotals)
      .setFilters(filters)
      .get(`${url}/`);
  },

  async compareProvencaleToDeliveryNote(orderId) {
    return await apiService.get(`${url}/${orderId}/compareProvencaleToDeliveryNote`);
  },

  async getProvencaleOrderInfo(orderId) {
    return await apiService.get(`${url}/${orderId}/provencale-status`);
  },

  async getSingle(orderId) {
    return await apiService.get(`${url}/${orderId}`);
  },

  async getOrderPDF(orderId) {
    return await apiService.downloadBlob(`${url}/${orderId}/pdf`);
  },

  async getProductSuggestions(supplierOrderId, sorts = [], filters = []) {
    return await apiService.setSorts(sorts).setFilters(filters).get(`${url}/${supplierOrderId}/productsSuggestions`);
  },

  async finalizeOrder(orderId, payload) {
    return await apiService.patch(`${url}/${orderId}/finalize`, payload);
  },

  async deleteSingle(supplierOrderId) {
    return await apiService.delete(`${url}/${supplierOrderId}`);
  },

  async addItemToOrder(supplierOrderId, payload) {
    return await apiService.post(`${url}/${supplierOrderId}/addItem`, payload);
  },

  async editItem(supplierOrderId, payload) {
    return await apiService.patch(`${url}/${supplierOrderId}/editItem`, payload);
  },

  async setReceivedQuantity(productId, quantity) {
    return await apiService.patch(`${url}/product/${productId}/setReceivedQuantity`, {
      quantity
    });
  },

  async setReceivedQuantities(supplierOrderId, items, treatedByManager = true) {
    return await apiService.patch(`${url}/batchAction`, {
      jobAction: "setReceivedQuantities",
      items,
      params: {
        supplierOrderId,
        treatedByManager
      }
    });
  },

  async batchSetReceivedQuantityForTouchInterface(supplierOrderId, items, treatedByManager = true) {
    return await apiService.patch(`${url}/batchSetReceivedQuantity`, {
      jobAction: "setReceivedQuantities",
      items,
      params: {
        supplierOrderId,
        treatedByManager
      }
    });
  },

  async executeBatchAction(action, items, params) {

    return await apiService.patch(`${url}/batchAction`, {
      jobAction: action,
      items: items,
      params: params
    });
  },

  async uploadBatchAction(action, items, params) {

    let formData = new FormData();

    for (const [index, file] of params.filesToUpload.entries()) {
      formData.append(`file[${index}]`, file.file);
    }

    formData.append('jobAction', action);
    formData.append('items', JSON.stringify(items));

    return await apiService.post(`${url}/batchAction?_method=PATCH`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },

  async batchAddProductItems(supplierOrderId, items, params) {
    return await apiService.patch(`${url}/batchAction`, {
      jobAction: "addProductItems",
      items,
      params: {
        supplierOrderId,
        ...params
      }
    });
  },

  async deleteItem(supplierOrderId, itemId) {
    return await apiService.delete(`${url}/${supplierOrderId}/deleteItem`, {
      data: {
        itemId: itemId
      }
    })
  },


  async getLinkedInvoicePDFs(supplierOrderId) {
    return await apiService.get(`${url}/${supplierOrderId}/linked-invoice-pdfs`);
  },

  async unlinkLinkedInvoicePDF(supplierOrderId, invoicePDFId) {
    return await apiService.delete(`${url}/${supplierOrderId}/linked-invoice-pdfs/${invoicePDFId}/unlink`);
  },

  async uploadAndLinkInvoicePDF(supplierOrderId, fileList) {
    let formData = new FormData();

    for (const [index, file] of fileList.entries()) {
      formData.append(`file[${index}]`, file.file);
    }

    return await apiService.post(`${url}/${supplierOrderId}/upload-link-invoice-pdfs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
}
