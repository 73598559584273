<template>
  <div>
    <div
      class="flex flex-wrap gap-x-4 gap-y-2 items-center justify-between flex-col md:flex-row justify-center lg:justify-between mb-4">
      <div
        class="flex flex-wrap items-center gap-4 justify-between flex-col md:flex-row justify-center md:justify-start mb-4">
        <page-title without-margin>
          {{ getTitle }}
        </page-title>
        <delivery-status-indicator
          v-if="status"
          :status="status"
          class="ml-2"
        />
      </div>

      <div class="flex flex-wrap flex-col md:flex-row justify-center md:justify-start gap-4 items-center">
        <card size="small">
          <div class="flex items-center justify-between">
            <box-quantity
              :quantity="boxQuantity"
            />

            <template v-if="!$isReadOnly()">
              <button-element class="ml-3" size="small" type="light" @click="addBox">
                <fa-icon fixed-width icon="plus"/>
              </button-element>
              <button-element class="ml-1" size="small" type="light" @click="removeBox">
                <fa-icon fixed-width icon="minus"/>
              </button-element>
            </template>
          </div>
        </card>

        <card size="small">
          <div class="flex items-center justify-between gap-x-2">
            <div class="text-3xl font-bold text-luxcaddy-400">
              N°
            </div>
            <fulfilment-number-display
              :fulfilment-number="fulfilmentNumber || '--'"
            />
            <button-element
              :disabled="fulfilmentNumber !== null || $isReadOnly()"
              class="ml-4"
              size="small"
              type="light"
              @click="attributeFulfilmentNumber"
            >
              Set
            </button-element>
          </div>
        </card>
        <div class="flex gap-x-4 items-center">
          <button-element
            :disabled="isCanceled || $isReadOnly() || isDelivered"
            type="danger"
            @click="cancel"

          >
            Annuler
          </button-element>

          <button-element
            :disabled="isCanceled || $isReadOnly() || isDelivered"
            type="light"
            @click="$refs.markDeliveryAsDelivered.openModal()"
          >
            Marquer comme livré
          </button-element>


        </div>
      </div>
    </div>

    <div class="bg-white border p-4 mb-4">
      <div class="flex gap-x-6">
        <div class="flex gap-x-8 items-center">
          <span class="font-bold">ID Client:</span>
          <member-quick-action-modal
            v-if="memberId"
            :id="memberId"
          />
        </div>

        <div class="flex gap-x-8 items-center">
          <span class="font-bold">ID Commande:</span>
          <text-link
            v-if="orderId !== null"
            :to="{name: 'orders.edit', params: {order: orderId}}"
          >
            {{ orderId }}
          </text-link>
          <span v-else>/</span>
        </div>

        <div v-if="subscriptionId" class="flex gap-x-8 items-center">
          <span class="font-bold">ID Abonn:</span>
            <text-link
                    v-if="subscriptionId !== null"
                    :to="{name: 'subscriptions.list', query: {filter_subscriptionIds: subscriptionId}}"
            >
                {{ subscriptionId }}
            </text-link>
          <span v-else>/</span>
        </div>

        <div class="flex gap-x-8 items-center">
          <span class="font-bold">Livreur:</span>
          <span>
            {{ driverMemberName || '/' }}
          </span>
        </div>

        <text-link
          :to="{
                name: 'route-manager.index',
                query: {
                  date: $date(this.deliveryTimeslotStart).format('YYYY-MM-DD'),
                  deliveryId: this.getDeliveryId
                }
            }">
          Voir sur route manager
        </text-link>



      </div>
    </div>

    <card>
      <card-tab id="deliveryTab" icon="truck" title="Livraison">
        <div v-if="geocodingLatitude && geocodingLongitude"
             class="grid grid-cols-1 lg:grid-cols-2 gap-8"
        >
          <geocoding-management-map
            :disableToogle="disableGeocoding"
            :is-precise.sync="isPrecise"
            :latitude.sync="geocodingLatitude"
            :longitude.sync="geocodingLongitude"
            :manual-geocoding.sync="manualGeocoding"
            :map-height-in-px="600"
          />

          <form-wrapper
            ref="form"
            :show-changed="true"
            :show-loading-indicator="isLoading"
            submit-button-label="Sauvegarder"
            @submit="updateDelivery"
          >

            <modal
              ref="timeslotModal"
              :title="`Changer créneau d'horaire - Zone originale: ${deliveryZone || ''}`"
              icon="clock"
              @open="$refs.timeSlotSelection.fetchTimeslotsForZone()"
            >
              <time-slot-selection
                v-if="deliveryZone"
                ref="timeSlotSelection"
                :initial-fetch="false"
                :selected-id="selectedNewDeliveryTimeslotId"
                :zone-id="deliveryZone"
                class="w-full"
                used-in-modal
                @change="onTimeslotSelect"
              />
              <div class="flex space-x-4 mt-4 justify-end">
                <button-element type="light" @click="closeModal">Annuler</button-element>
                <button-element
                  :disabled="selectedNewDeliveryTimeslotId === null"
                  @click="changeTimeSlot"
                >
                  <fa-icon class="mr-2" icon="check"/>
                  Sélectionner
                </button-element>

              </div>
            </modal>

            <!-- Used to display "form changed" when selecting a new timeslot -->
            <validation-provider
              ref="validationProvider"
              name="timeslotId"
              slim
            >
              <input
                v-model="deliveryTimeslotId"
                type="hidden"
              />
            </validation-provider>

            <div class="grid grid-cols-1 gap-4 mb-2">
              <div class="border flex justify-between p-4">
                <div class="text-xl">
                  <div class="font-bold mb-3">Créneau de livraison</div>
                  <time-slot-as-string
                    :from="$date(deliveryTimeslotStart)"
                    :to="$date(deliveryTimeslotEnd)"
                    :zone="deliveryZone"
                  />
                </div>


                <div v-if="!$isReadOnly() && !isDelivered">
                  <tooltip :text="deliveryZone === null ? 'Le code postal n\'a pas de zone' : ''">
                    <button-element :disabled="deliveryZone === null" type="warning"
                                    @click="openChangeTimeslotModal">
                      Changer
                      <fa-icon class="ml-2" fixed-width icon="edit"></fa-icon>
                    </button-element>
                  </tooltip>
                </div>
              </div>
            </div>

            <div v-if="!isDelivered" class="grid grid-cols-1 gap-4 mb-4">
              <div class="border flex justify-between p-4">
                <div class="text-xl">
                  <div class="font-bold mb-3">Changement contact de livraison</div>
                  <delivery-contact-select-input
                    v-model="selectedNewDeliveryContact"
                    :label="null"
                    :member-id="memberId"
                    :occupy-validation-space="false"
                    class="w-full"
                    @input="onDeliveryContactChange"
                  />
                </div>

              </div>
            </div>

            <div class="mb-2">
              <div class="border p-4">
                <div class="font-bold mb-3 text-xl">Changement ID Commande</div>

                <div class="flex items-center gap-4">
                  <text-input-element
                    v-model="orderId"
                    :disabled="isDelivered || !editOrderIdMode"
                    :occupy-validation-space="false"
                  />

                  <div v-if="!$isReadOnly() && !isDelivered">
                    <toggle-element
                      v-model="editOrderIdMode"
                      disabled-icon="lock"
                      enabled-icon="unlock"
                      enabled-text="Activer le champ"
                      label-size="xsmall"
                    />
                  </div>
                </div>

                <div
                  v-if="editOrderIdMode"
                  class="mt-2.5 | text-gray-500 text-sm"
                >
                  Pour délier la livraison d'une commande, il suffit de vider le champ. N'oubliez pas de cliquer sur
                  "Sauvegarder" !
                </div>
              </div>
            </div>

            <div class="mt-6 | grid grid-cols-1 lg:grid-cols-4 gap-4">

              <title-select-input
                v-model="title"
                :disabled="isDelivered"
              />
              <text-input-element
                v-model="firstName"
                :disabled="isDelivered"
                label="Prénom"
              />
              <text-input-element
                v-model="familyName"
                :disabled="isDelivered"
                label="Name"
              />

              <text-input-element
                v-model="companyName"
                :disabled="isDelivered"
                label="Société"
              />
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <text-input-element
                v-model="street"
                :disabled="isDelivered"
                class="lg:col-span-2"
                label="Rue et numéro"
                validation-rules="required"
              />

              <floor-select-input
                v-model="floor"
                :allow-unselecting="false"
                :disabled="isDelivered"
                validation-rules="required"
              />
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <zone-postal-code-input
                v-model="postalCode"
                :disabled="isDelivered"
                label="Code postal"
                validation-rules="required|luxembourgish_postal_code"
              />
              <text-input-element
                v-model="city"
                :disabled="isDelivered"
                label="Ville"
                validation-rules="required"
              />
              <country-select-input
                v-model="country"
                :allow-unselecting="false"
                :disabled="isDelivered"
                only-luxembourg
                validation-rules="required"
              />
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">

              <country-phone-prefix-select-input
                v-model ="phonePrefix"
                :allow-unselecting="false"
                class="col-span-12 md:col-span-1"
                label="Phone prefix"
                validation-rules="required"
              />
              <phone-input-element
                v-model="phone"
                :disabled="isDelivered"
                class="col-span-12 md:col-span-3"
                validation-rules="required"
              />

              <text-input-element
                v-model="instructions"
                :disabled="isDelivered"
                class="lg:col-span-4"
                label="Instruction du client en cas de abscence"
                validation-rules="required"
              />

              <text-input-element
                v-model="description"
                :disabled="isDelivered"
                class="lg:col-span-4"
                label="Description"
              />


              <date-picker-element
                v-model="deliveredAt"
                :only-date="false"
                label="Livré à"
                with-time
              />
              <member-select-input
                v-model="deliveredBy"
                :occupy-validation-space="false"
                :only-member-types="['employee']"
                label="Livreur"
              />
              <select-element
                v-model="wasCustomerPresent"
                :enable-search="false"
                :options="getClientTypeOptions"
                class="lg:col-span-2"
                label="Présence du client"
              />
              <checkbox-element
                v-model="forceHasFrozenItems"
                :disabled="isDelivered"
                label="A des articles congelés?(Force)"
              >
              </checkbox-element>
              <checkbox-element
                v-model="forceHasCooledItems"
                :disabled="isDelivered"
                label="A des articles réfrigérés? (Force)"
              >

              </checkbox-element>

            </div>

            <checkbox-element
              v-model="updateContact"
              :disabled="isDelivered"
              :occupy-validation-space="false"
              label="Mise à jour de l'adresse de livraison du contact de livraison du client"
            >

            </checkbox-element>

          </form-wrapper>

        </div>
      </card-tab>

      <card-tab id="signatureTab" icon="signature" title="Signature">
        <show-delivery-signature :delivery-id="getDeliveryId"/>
      </card-tab>

      <card-tab id="instructionTab" icon="book" title="Instructions">
        <delivery-instruction-management :delivery-id="getDeliveryId"/>
      </card-tab>

      <card-tab id="deliveryLogs" icon="file-alt" title="Logs">
        <show-logs-table
          :default-loggable-id="getDeliveryId"
          default-loggable-type="Delivery"
        />
      </card-tab>
    </card>


    <mark-delivery-as-delivered-modal ref="markDeliveryAsDelivered" :delivery-id="getDeliveryId"
                                      @change="onMarkDeliveryAsDelivered"/>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/pages/PageTitle";
import Card from "@/components/elements/cards/Card";
import FormWrapper from "@/components/elements/forms/FormWrapper";
import Modal from "@/components/elements/modals/Modal";
import TimeSlotSelection from "@/components/global/TimeSlots/TimeSlotSelection";
import ButtonElement from "@/components/elements/buttons/ButtonElement";
import TitleSelectInput from "@/components/elements/forms/elements/TitleSelectInput";
import TextInputElement from "@/components/elements/forms/elements/base/TextInputElement";
import FloorSelectInput from "@/components/elements/forms/elements/FloorSelectInput";
import CountrySelectInput from "@/components/elements/forms/elements/CountrySelectInput";
import DeliveryRepository from "@/repositories/DeliveryRepository";
import CardTab from "@/components/elements/cards/tabs/CardTab";
import DeliveryInstructionManagement
  from "@/components/pages/Deliveries/EditDelivery/Instructions/DeliveryInstructionManagement";
import CheckboxElement from "@/components/elements/checkboxes/CheckboxElement";
import GeocodingManagementMap
  from "@/components/pages/Members/EditMember/Tabs/DeliveryContacts/Form/Includes/GeocodingManagementMap";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";
import TextLink from "@/components/global/TextLink";
import DatePickerElement from "../../../elements/forms/elements/base/DatePickerElement";
import DeliveryStatusIndicator from "../../../elements/display/deliveries/DeliveryStatusIndicator";
import FulfilmentNumberDisplay from "@/components/global/FulfilmentNumber/FulfilmentNumberDisplay";
import TimeSlotAsString from "../../../global/TimeSlots/TimeSlotAsString";
import BoxQuantity from "@/components/global/BoxQuantity/BoxQuantity";
import Tooltip from "../../../elements/tooltips/Tooltip";
import ZonePostalCodeInput from "../../Members/EditMember/Tabs/DeliveryContacts/Includes/ZonePostalCodeInput";
import {ValidationProvider} from "vee-validate";
import DeliveryContactSelectInput from "@/components/elements/forms/elements/DeliveryContactSelectInput.vue";
import ShowLogsTable from "@/components/pages/Logs/ShowLogsTable.vue";
import PhoneInputElement from "@/components/elements/forms/elements/PhoneInputElement.vue";
import MarkDeliveryAsDeliveredModal from "@/components/pages/Deliveries/EditDelivery/MarkDeliveryAsDeliveredModal.vue";
import MemberSelectInput from "@/components/elements/forms/elements/MemberSelectInput.vue";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement.vue";
import ShowDeliverySignature from "@/components/pages/Deliveries/EditDelivery/Signature/ShowDeliverySignature.vue";
import ToggleElement from "@/components/elements/toggles/ToggleElement.vue";
import CountryPhonePrefixSelectInput from "@/components/elements/forms/elements/CountryPhonePrefixSelectInput.vue";

export default {
  name: "EditDelivery",
  components: {
      CountryPhonePrefixSelectInput,
    ToggleElement,
    ShowDeliverySignature,
    SelectElement,
    MemberSelectInput,
    MarkDeliveryAsDeliveredModal,
    PhoneInputElement,
    ShowLogsTable,
    DeliveryContactSelectInput,
    BoxQuantity,
    TimeSlotAsString,
    FulfilmentNumberDisplay,
    DatePickerElement,
    TextLink,
    MemberQuickActionModal,
    GeocodingManagementMap,
    CheckboxElement,
    DeliveryInstructionManagement,
    CardTab,
    CountrySelectInput,
    FloorSelectInput,
    TextInputElement,
    TitleSelectInput,
    ButtonElement,
    TimeSlotSelection,
    Modal,
    FormWrapper,
    Card,
    PageTitle,
    DeliveryStatusIndicator,
    Tooltip,
    ZonePostalCodeInput,
    ValidationProvider
  },
  data() {
    return {
      editOrderIdMode: false,
      selectedNewDeliveryTimeslotId: null,
      selectedNewDeliveryTimeslotStart: null,
      selectedNewDeliveryTimeslotEnd: null,
      selectedNewZoneId: null,
      active: false,
      deliveryTimeslotId: null,
      deliveryTimeslotStart: null,
      deliveryTimeslotEnd: null,
      deliveryZone: null,

      selectedNewDeliveryContact: null,

      orderId: null,
      memberId: null,
      driverMemberName: null,
      deliveredBy: null,

      description: "",
      title: "",
      firstName: "",
      familyName: "",
      companyName: null,
      street: "",
      postalCode: "",
      city: "",
      country: "",
      instructions: null,
      floor: 0,
      destination: null,
      phone: null,
      phonePrefix: null,

      boxQuantity: 0,
      fulfilmentNumber: null,

      updateContact: false,

      zoneId: null,

      //Geocoding
      manualGeocoding: false,
      disableGeocoding: false,
      geocodingLatitude: null,
      geocodingLongitude: null,
      isPrecise: false,
      deliveredAt: null,
      isDelivered: false,
      forceHasFrozenItems: false,
      forceHasCooledItems: false,
      status: null,
      wasCustomerPresent: null,
        subscriptionId: null
    }
  },
  watch: {
    manualGeocoding(newBool, oldBool) {
      // Set from Manual to Automatic
      if (oldBool === true && newBool === false) {
        if (this.isGeoCodingValid(this.destination.geocoding)) {
          this.geocodingLongitude = this.destination.geocoding.longitude;
          this.geocodingLatitude = this.destination.geocoding.latitude;
          this.isPrecise = this.destination.geocoding.isPrecise;
        }
      }
    }
  },
  mounted() {
    this.fetchDelivery();
  },
  methods: {
    onMarkDeliveryAsDelivered() {
      this.fetchDelivery();
    },
    onDeliveryContactChange(dc) {
      this.title = dc.title;
      this.firstName = dc.firstName;
      this.familyName = dc.familyName;
      this.companyName = dc.companyName;
      this.street = dc.street;
      this.postalCode = dc.postalCode;
      this.city = dc.city;
      this.country = dc.country;
      this.instructions = dc.instructions;
      this.phone = dc.phone;
      this.phonePrefix = dc.phonePrefix;
      this.floor = dc.floor;
    },

    fetchDelivery() {
      return DeliveryRepository.getSingle(this.$route.params.deliveryId).then((res) => {
        const data = res.data.data;

        this.orderId = data.orderId;
        this.subscriptionId = data.subscriptionId;

        this.memberId = data.memberId;
        this.status = data.status;

        this.deliveryTimeslotId = data.timeSlot.id;
        this.deliveryTimeslotStart = data.timeSlot.startDate;
        this.deliveryTimeslotEnd = data.timeSlot.endDate;
        this.deliveryZone = data.timeSlot.zoneId;

        this.driverMemberName = data.driverMemberName;
        this.deliveredBy = data.deliveredBy || null;


        this.description = data.description;
        this.title = data.destination.title;
        this.firstName = data.destination.firstName;
        this.familyName = data.destination.familyName;
        this.companyName = data.destination.companyName;
        this.phonePrefix = data.destination.phone;
        this.street = data.destination.street;
        this.postalCode = data.destination.postalCode;
        this.city = data.destination.city;
        this.country = data.destination.country;
        this.instructions = data.destination.instructions;
        this.phone = data.destination.phone;
        this.phonePrefix = data.destination.phonePrefix;
        this.floor = data.destination.floor;

        this.wasCustomerPresent = data.wasCustomerPresent ? 1 : 0;

        this.boxQuantity = data.boxQuantity;
        this.fulfilmentNumber = data.fulfilmentNumber;

        this.zoneId = data.destination.zoneId;

        this.updateContact = false;
        this.forceHasCooledItems = data.forceHasCooledItems;
        this.forceHasFrozenItems = data.forceHasFrozenItems;

        this.deliveredAt = data.deliveredAt;
        this.isDelivered = this.deliveredAt !== null;
        // Geocoding
        this.destination = res.data.data.destination;
        let geocoding = this.destination?.geocoding;

        if (this.isGeoCodingValid(geocoding)) {
          if (geocoding?.isManual) {
            this.disableGeocoding = geocoding?.isManual;
            this.manualGeocoding = geocoding?.isManual;
          } else {
            this.disableGeocoding = false;
            this.manualGeocoding = false;
          }
          this.geocodingLatitude = geocoding.latitude;
          this.geocodingLongitude = geocoding.longitude;
          this.isPrecise = geocoding.isPrecise;
        } else {
          this.setDefaultMarker();
        }

      }).then(() => this.$refs.form.resetForm());
    },
    isGeoCodingValid(geocoding) {
      if (geocoding === null) {
        return false;
      }
      return geocoding.latitude && geocoding.longitude;
    },
    setDefaultMarker() {
      this.isPrecise = false;
      this.manualGeocoding = false;
      this.geocodingLatitude = 49.755461;
      this.geocodingLongitude = 6.117866;
      this.disableGeocoding = false;
    },

    updateDelivery(callback) {
      return DeliveryRepository.update(this.$route.params.deliveryId,
        this.getPayload
      ).then(() => {
        this.$sendSuccessToast(`Livraison a été modifié!`);
        this.editOrderIdMode = false;
        this.fetchDelivery().then(() => {
          callback();
        })
      }).finally(() => callback());
    },
    openChangeTimeslotModal() {
      this.$refs.timeslotModal.openModal();
    },
    onTimeslotSelect(timeslot) {
      this.selectedNewDeliveryTimeslotId = timeslot.id;
      this.selectedNewDeliveryTimeslotStart = timeslot.start;
      this.selectedNewDeliveryTimeslotEnd = timeslot.end;
      this.selectedNewZoneId = timeslot.zone;
    },
    closeModal() {
      this.$refs.timeslotModal.closeModal();
    },
    changeTimeSlot() {
      this.deliveryTimeslotId = this.selectedNewDeliveryTimeslotId;
      this.deliveryTimeslotStart = this.selectedNewDeliveryTimeslotStart;
      this.deliveryTimeslotEnd = this.selectedNewDeliveryTimeslotEnd;
      this.deliveryZone = this.selectedNewZoneId;

      this.$refs.validationProvider.syncValue(this.deliveryTimeslotId);

      this.$refs.timeslotModal.closeModal();

      this.selectedNewDeliveryTimeslotId = null;
      this.selectedNewDeliveryTimeslotStart = null;
      this.selectedNewDeliveryTimeslotEnd = null;
      this.selectedNewZoneId = null;
    },

    /*
        BOX QUANTITY
     */
    addBox() {
      if (this.boxQuantity >= 99)
        return false;

      const newQuantity = this.boxQuantity + 1;
      this.setBoxQuantity(newQuantity);
    },
    removeBox() {
      if (this.boxQuantity === 0)
        return false;

      const newQuantity = this.boxQuantity - 1;
      this.setBoxQuantity(newQuantity);
    },
    setBoxQuantity(quantity) {
      DeliveryRepository
        .setBoxQuantity(this.$route.params.deliveryId, quantity)
        .then(() => {
          this.boxQuantity = quantity;
        }).catch(() => {
        this.fetchDelivery();
      })
    },
    attributeFulfilmentNumber() {
      DeliveryRepository
        .attributeFulfilmentNumber(this.$route.params.deliveryId)
        .then(() => {
          this.fetchDelivery();
        });
    },
    cancel() {
      if (confirm('Certain ?')) {
        DeliveryRepository
          .cancel(this.$route.params.deliveryId)
          .then(() => {
            this.fetchDelivery();
          });
      }
    },

  },
  computed: {
    getClientTypeOptions() {
      return [
        {
          label: 'Client absent',
          value: 0
        },
        {
          label: 'Client présent',
          value: 1
        },
      ];

    },
    getDeliveryId() {
      return this.$route.params.deliveryId;
    },
    isCanceled() {
      return this.status === 'canceled';
    },

    getPayload() {
      let payload = {
        deliveryTimeslotId: this.deliveryTimeslotId,
        description: this.description === null ? "" : this.description,
        title: this.title,
        firstName: this.firstName,
        familyName: this.familyName,
        companyName: this.companyName,
        phoneNumber: this.phone,
        phonePrefix: this.phonePrefix,
        street: this.street,
        postalCode: this.postalCode,
        city: this.city,
        country: this.country,
        instructions: this.instructions,
        floor: this.floor,
        updateContact: this.updateContact,
        manualGeocoding: this.manualGeocoding,
        forceHasFrozenItems: this.forceHasFrozenItems,
        forceHasCooledItems: this.forceHasCooledItems,
        wasCustomerPresent: this.wasCustomerPresent,
        deliveredAt: this.deliveredAt,
        deliveredBy: this.deliveredBy,
        orderId: this.orderId
      };

      if (this.manualGeocoding) {
        payload.latitude = this.geocodingLatitude;
        payload.longitude = this.geocodingLongitude;
      }

      return payload;
    },
    isLoading() {
      return this.$store.getters['loading/isRunningRequest'];
    },

    getCustomerName() {
      if (this.firstName === null && this.familyName === null) {
        return this.companyName ?? '';
      }
      let name = `${this.firstName} ${this.familyName}`;

      if (this.companyName) {
        name = name + ` - ${this.companyName}`;
      }

      return name;
    },
    getTitle() {
      return `Livr. ${this.getCustomerName} #${this.getDeliveryId}`
    }
  }
}
</script>

